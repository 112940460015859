@import "../../shared/font";

.survey-page {
  height: 100%;
  padding: 40px;
  background-color: #ECEAE5;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.survey-page-header-logo {

}

.survey-page-header-close-button {

}

.survey-page-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

